.Container{
    display:flex;
    flex-direction: column;
    width:500px;
    align-items:center;
    justify-content: center;

}

table td{
    
    border: 1px solid rgb(193, 47, 255);
    width:100px;
    height:100px;
}
